import T from "@marginly/ui/components/typography";
import { PostOrPage } from "@tryghost/content-api";
import React from "react";

import { blogCardClick } from "@/util/tracking";

import Space from "../../common/space";
import { formatDate } from "../../util/date";
import { Blog } from "../styled";
import {
  BlogArticle,
  BlogContent,
  BlogImage,
  BlogImageCover,
  BlogInfo,
} from "./styled";

const BlockItem = ({ post }: { post: PostOrPage }) => {
  const { title, feature_image, url, created_at, updated_at, tags } = post;
  const articleDate = updated_at || created_at;

  return (
    <Blog
      href={url}
      className="blog"
      target="_blank"
      onClick={() => blogCardClick(url || "")}
    >
      <BlogImageCover>
        <BlogImage src={feature_image || ""} alt="" />
      </BlogImageCover>
      <BlogContent>
        <BlogArticle>
          <T headerS>{title}</T>
        </BlogArticle>
        <Space height={16} heightMobile={16} />
        <BlogInfo>
          <T secondary body>
            {tags?.[0].name}
          </T>
          {!!articleDate && (
            <T secondary body>
              {formatDate(articleDate)}
            </T>
          )}
        </BlogInfo>
      </BlogContent>
    </Blog>
  );
};

export default BlockItem;
