import styled from "styled-components";

export const BlogImageCover = styled.div`
  height: 212px;
  max-width: calc(100% - 16px);
  margin: 8px 8px 16px;
  border-radius: var(--rounding-radius-xl, 24px) var(--rounding-radius-xl, 24px)
    var(--rounding-radius-xs, 8px) var(--rounding-radius-xs, 8px);
  overflow: hidden;
`;

export const BlogImage = styled.img`
  display: block;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const BlogContent = styled.div`
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 2;
`;

export const BlogArticle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BlogInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
