"use client";

import styled from "styled-components";

import { Container } from "../container.styled";

export const HeaderWrapper = styled.header`
  z-index: 1500;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: 400ms ease-out;
  transform: translateY(56px);
  background: rgba(255, 255, 255, 0.16);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  // .discord {
  //   backdrop-filter: blur(8px);
  //   -webkit-backdrop-filter: blur(8px);
  // }

  &.scrolled {
    // background: var(--background-accent-secondary, #defef9);
  }

  &.nobanner {
    transform: translateY(0);
  }

  .mobile-logo {
    display: none;
  }

  ${Container} {
    @media (min-width: 1023px) {
      padding: 0px var(--spacing-space-48, 48px);
      max-width: 100%;
    }
  }
  @media (max-width: 1023px) {
    transform: translateY(-100%);
    &.scrolled {
      transform: translateY(0);
    }
    ${Container} {
      padding: 0 16px;
      // padding-bottom: 0;
    }
  }

  @media (max-width: 1024px) {
    // transform: translateY(64px);
    transform: translateY(-10px);
    .desktop-logo {
      display: none;
    }
    .mobile-logo {
      display: block;
      width: 26px;
      object-fit: contain;
    }
  }

  &.scrolled {
    transform: translateY(0);
  }

  @media (min-width: 1024px) {
    &.scrolled {
      background: transparent;
    }
  }
`;

export const Nav = styled.nav`
  border-radius: 0;
  padding: 0;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1024px) {
    height: 96px;
  }
`;

export const TicketButtonWrapper = styled.div`
  margin-left: auto;
  margin-right: 8px;

  .md {
    border-radius: var(--rounding-radius-xs, 8px);
    p,
    .action {
      color: #000;
    }
    background: var(
      --Raffle,
      conic-gradient(
        from 250deg at 74.63% 75.32%,
        #9fe 0deg,
        #ffe3dd 84.375deg,
        #ddefff 264.375deg,
        #9fe 360deg
      )
    );
    &:hover {
      background: var(
        --Raffle,
        conic-gradient(
          from 250deg at 74.63% 75.32%,
          #9fe 0deg,
          #ffe3dd 84.375deg,
          #ddefff 264.375deg,
          #9fe 360deg
        )
      );
      p {
        color: #000;
      }
    }
  }
`;

export const HeaderButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  @media (min-width: 1024px) {
    gap: 16px;
  }
`;

export const HeaderBanner = styled.div`
  background: var(--fill-primary, #000);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 12px 16px;
  transition: 300ms ease-out;
  z-index: 1502;
  &.secondary {
    background: #9fe;
    p {
      color: var(--text-primary, #000);
    }
  }
  .decor {
    height: 24px;
    width: auto;
    object-fit: contain;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0;
  }
  p {
    color: var(--text-primary-on-dark, #fff);
  }
  .accent {
    color: var(--text-accent, #5cdfd4);
    white-space: nowrap;
  }
  .brand {
    height: 32px;
    border-radius: var(--rounding-radius-m, 16px);
    padding: 5px 12px 7px;
    transition: 400ms ease-out;
    flex-shrink: 0;
    white-space: nowrap;
    cursor: pointer;
    p {
      color: var(--text-primary-on-brand, #000);
      font-weight: 700;
      font-variation-settings: "wght" 700;
    }
    &:hover {
      background: var(--fill-accent-primary-hover, #5cdfd4);
    }
  }
  &.scrolled {
    transform: translateY(-110%);
  }

  @media (max-width: 1024px) {
    .headerS,
    .accent {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      font-variation-settings: "wght" 600;
      line-height: 20px; /* 125% */
      letter-spacing: 0.32px;
    }
  }
`;

export const AirdropButton = styled.div`
  border-radius: var(--rounding-radius-m, 16px);
  display: flex;
  padding: var(--spacing-space-4, 4px) var(--spacing-space-12, 12px)
    var(--spacing-space-4, 4px) var(--spacing-space-6, 6px);
  align-items: center;
  gap: var(--spacing-space-8, 8px);
  background: #c8ff26;
  .action {
    color: var(--text-primary);
    /* color: #000; */
  }
  img {
    width: 24px;
    height: 24px;
  }
`;
