"use client";

import Button from "@marginly/ui/components/button";
import T from "@marginly/ui/components/typography";
import { PostOrPage } from "@tryghost/content-api";
import NextLink from "next/link";
import { ChangeEvent, useCallback, useState } from "react";

import { EARN_URL, FARM_URL } from "@/constants/links";
import {
  trackAuditReportClick,
  trackBlogClick,
  trackEarnClick,
  trackFarmClick,
  trackFarmSparksClick,
  trackLearnMoreClick,
  trackProvideLiquidityClick,
  trackRefLinkClick,
} from "@/util/tracking";

import { Container } from "../common/container.styled";
import Footer from "../common/footer/footer";
import Header from "../common/header/header";
import Space from "../common/space";
import QaSection from "../qa-section";
import BlockItem from "./blog-item";
import {
  ApyHeader,
  CardsRow,
  CardTitle,
  CoinsWrapper,
  DoubleSparks,
  Form,
  GrayBlock,
  LeverageContent,
  LeverageRow,
  LeverageValue,
  LeverageWrapper,
  MarketsBlock,
  MarketsGrid,
  MarketsGridOverflow,
  MarketUnit,
  Opening,
  OpeningContent,
  OpeningTitle,
  ReferalIcon,
  ReferInput,
  Referral,
  ReferralRow,
  Report,
  ReportButtons,
  ReportIcon,
  Schema,
  SchemaBlock,
  SchemaCard,
  SparkBar,
  SparkCard,
  TokensRow,
} from "./sparks.styled";
import { BlogGrid, BlogOverflow, BlogTitle } from "./styled";

const br = <br />;

const LEARN_MORE_LINK =
  "https://blog.marginly.com/new-partners-in-leveraged-yield-farming-marginly-pendle/";

const Main = ({ posts }: { posts: PostOrPage[] }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    [setInputValue],
  );

  return (
    <>
      <Header />
      <Space height={96} heightMobile={64} />

      <Container>
        <section>
          <Opening>
            <OpeningContent>
              <OpeningTitle>
                Leverage LRT&nbsp;to farm more points faster
              </OpeningTitle>

              <T body>
                Boost your yield with leveraged PT&nbsp;LRT&nbsp;on&nbsp;Pendle
              </T>

              <NextLink
                href="https://app.marginly.com/mainnet/farm"
                onClick={() => trackFarmClick()}
              >
                <Button lg fullWidth className="btn-bootstrap">
                  <T action>Start Farming</T>
                </Button>
              </NextLink>
            </OpeningContent>

            <CoinsWrapper>
              <img src="/images/coins-top.png" alt="" />
            </CoinsWrapper>

            <Report>
              <ReportIcon>
                <img src="/images/qstamp.svg" alt="" className="qstamp" />
                <img
                  src="/images/checkmark-blue.svg"
                  alt=""
                  className="checkmark"
                />
              </ReportIcon>
              <T body>Smart contracts audited by&nbsp;Quantstamp</T>

              <ReportButtons>
                <NextLink
                  href="https://698120604-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FQiFBW4qPpAjrKQaezZ3s%2Fuploads%2F44riQdio4FbpMe9uuLzp%2Fmarginly-final-report.pdf?alt=media&token=e96e516a-ca0f-4eec-8470-88602db76f58"
                  target="_blank"
                  onClick={() => trackAuditReportClick()}
                  rel="noreferrer noopener"
                >
                  <Button elevated sm>
                    <T action>Report 1</T>
                  </Button>
                </NextLink>

                <NextLink
                  href="https://698120604-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FQiFBW4qPpAjrKQaezZ3s%2Fuploads%2FQeydGxQVvNL332dL7Xi2%2FMarginly%20Oracle%20Feature%20Final%20Report%202.pdf?alt=media&token=53e57e0d-ef9a-40e4-80aa-c85cd2202976"
                  target="_blank"
                  onClick={() => trackAuditReportClick()}
                  rel="noreferrer noopener"
                >
                  <Button elevated sm>
                    <T action>Report 2</T>
                  </Button>
                </NextLink>
              </ReportButtons>
            </Report>
          </Opening>
        </section>

        <Space height={192} heightMobile={96} />

        <section>
          <GrayBlock>
            <ApyHeader>
              <T headerL>Boost your APY on&nbsp;Pendle</T>
              <NextLink
                href={LEARN_MORE_LINK}
                onClick={() => trackLearnMoreClick()}
                target="_blank"
              >
                <Button text>
                  <T action>Learn More</T>
                </Button>
              </NextLink>
            </ApyHeader>
            <Space height={42} heightMobile={40} />

            <Schema>
              <SchemaBlock>
                <SchemaCard>
                  <CardTitle>
                    <T headerS>Pendle</T>
                    <img src="/images/PendleIcon.svg" alt="" />
                  </CardTitle>
                  <Space height={32} heightMobile={16} />
                  <TokensRow>
                    <img src="/images/thumbnail-group-2.png" alt="" />
                    <div>
                      <T body>PT tokens</T>
                      <T caption secondary>
                        Amphor, Bedrock, Ethena, Ether.fi, Kelp,Puffer
                      </T>
                    </div>
                  </TokensRow>
                </SchemaCard>
              </SchemaBlock>

              <SchemaBlock>
                <LeverageRow>
                  <img src="/images/cross-thin.svg" alt="" className="cross" />
                  <LeverageWrapper>
                    <LeverageContent>
                      <img src="/images/lev-logo.svg" alt="" />
                      <T action>Leverage</T>
                      <LeverageValue>
                        <T action>10&times;</T>
                      </LeverageValue>
                    </LeverageContent>
                  </LeverageWrapper>
                  <img src="/images/equality.svg" alt="" />
                </LeverageRow>
              </SchemaBlock>

              <SchemaBlock>
                <CardsRow>
                  <NextLink href={EARN_URL} onClick={trackEarnClick}>
                    <SchemaCard className="inrow">
                      <T headerS>LPs</T>
                      <img src="/images/lps.png" alt="" className="decor" />
                      <div>
                        <div className="colored">
                          <T caption>APY + Points</T>
                          <T headerS>Up to 65%</T>
                        </div>
                        <Button md fullWidth>
                          <T action>Earn</T>
                        </Button>
                      </div>
                    </SchemaCard>
                  </NextLink>

                  <NextLink href={FARM_URL} onClick={trackFarmClick}>
                    <SchemaCard className="inrow">
                      <T headerS>Farmers</T>
                      <img src="/images/farmers.png" alt="" className="decor" />

                      <div>
                        <div className="colored">
                          <T caption>APY</T>
                          <T headerS>Up to 135%</T>
                        </div>
                        <Button md fullWidth>
                          <T action>Farm</T>
                        </Button>
                      </div>
                    </SchemaCard>
                  </NextLink>
                </CardsRow>
              </SchemaBlock>
            </Schema>

            <div className="mobile-btn">
              <Space heightMobile={24} />
              <NextLink
                href={LEARN_MORE_LINK}
                onClick={() => trackLearnMoreClick()}
              >
                <Button md secondary fullWidth>
                  <T action>Learn More</T>
                </Button>
              </NextLink>
            </div>
          </GrayBlock>
        </section>

        <Space height={192} heightMobile={96} />

        <section>
          <GrayBlock>
            <DoubleSparks>
              <div>
                <T headerL>Double your Sparks</T>
                <Space height={0} heightMobile={6} />

                <NextLink
                  href="https://app.marginly.com/mainnet/earn"
                  onClick={() => trackFarmSparksClick()}
                >
                  <Button elevated lg icon className="desktop">
                    <img src="/images/spark-black.svg" alt="" />
                    <T action>Farm Sparks Now</T>
                  </Button>
                </NextLink>
              </div>

              <SparkCard>
                <SparkBar className="accent">
                  <img src="/images/deposit.svg" alt="" />
                  Now
                </SparkBar>
                <Space height={12} heightMobile={12} />
                <T headerM>Start farming Sparks</T>
                <Space height={12} heightMobile={12} />
                <T body>
                  Deposit ETH or stablecoins in our pools to double your Sparks
                  rewards
                </T>
                <img src="/images/spark1.png" alt="" className="pic" />
              </SparkCard>
              <SparkCard>
                <SparkBar>
                  <img src="/images/clock.svg" alt="" />
                  Soon
                </SparkBar>
                <Space height={12} heightMobile={12} />
                <T headerM>Earn Sparks—get&nbsp;Airdrop</T>
                <Space height={12} heightMobile={12} />
                <T body>
                  Each Sparkholder receives a portion of our future Airdrop
                </T>
                <img src="/images/spark2.png" alt="" className="pic" />
              </SparkCard>
              <NextLink
                href="https://app.marginly.com/mainnet/earn"
                onClick={() => trackFarmSparksClick()}
              >
                <Button elevated lg icon className="mobile">
                  <img src="/images/spark-black.svg" alt="" />
                  <T action>Earn Sparks</T>
                </Button>
              </NextLink>
            </DoubleSparks>
          </GrayBlock>
        </section>

        <Space height={192} heightMobile={96} />

        <section>
          <MarketsBlock>
            <T headerL>Redefining leveraged markets</T>
            <Space height={68} heightMobile={48} />
            <MarketsGridOverflow>
              <MarketsGrid>
                <MarketUnit>
                  <img src="/images/security.svg" alt="" />
                  <Space height={18} heightMobile={18} />
                  <T headerS>No impermanent loss</T>
                  <Space height={3} heightMobile={2} />
                  <T body secondary>
                    LPs provide only single-sided liquidity
                  </T>
                </MarketUnit>
                <MarketUnit>
                  <img src="/images/flash.svg" alt="" />
                  <Space height={18} heightMobile={18} />
                  <T headerS>Higher leverage</T>
                  <Space height={3} heightMobile={2} />
                  <T body secondary>
                    Protocol design enables outstanding yield boost
                  </T>
                </MarketUnit>
                <MarketUnit>
                  <img src="/images/bundle.svg" alt="" />
                  <Space height={18} heightMobile={18} />
                  <T headerS>Isolated pools</T>
                  <Space height={3} heightMobile={2} />
                  <T body secondary>
                    Pool design isolates and easily quantifies risks
                  </T>
                </MarketUnit>
              </MarketsGrid>
            </MarketsGridOverflow>
          </MarketsBlock>
        </section>
        {/* 
        <Space height={192} heightMobile={96} />
        <Referral>
          <SparkBar className="accent soon">
            <img
              src="/images/clock.svg"
              alt=""
              style={{ filter: "invert(1)" }}
            />
            Soon
          </SparkBar>
          <Space height={14} heightMobile={12} />
          <T headerL>Refer your frens</T>
          <Space height={10} heightMobile={8} />
          <T body secondary>
            Earn rewards from our affiliate program:
          </T>
          <Space height={32} heightMobile={32} />

          <ReferralRow>
            <ReferalIcon>
              <img src="/images/user.svg" alt="" />
            </ReferalIcon>
            <T body>Get 10% of Sparks farmed by your referrals</T>
          </ReferralRow>

          <Space height={24} heightMobile={24} />

          <ReferralRow>
            <ReferalIcon>
              <img src="/images/users.svg" alt="" />
            </ReferalIcon>
            <T body>
              Get 5% of Sparks farmed by the referrals of your referrals
            </T>
          </ReferralRow>

          <Space height={48} heightMobile={48} />

          <Form>
            <ReferInput
              value={inputValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e)}
              placeholder="Your email"
            />
            <Button icon lg elevated>
              <img src="/images/email.svg" alt="" />
              <T action>Notify Me</T>
            </Button>
          </Form>

          <NextLink
            href="https://app.marginly.com/sparks"
            onClick={() => trackRefLinkClick()}
          >
            <Button lg elevated>
              <T action>Get Referral Link</T>
            </Button>
          </NextLink>

          <img src="/images/referral-pic.png" alt="" className="decor" />
        </Referral> */}

        <Space height={192} heightMobile={96} />

        <section>
          <GrayBlock>
            <BlogTitle>
              <T headerL>Latest articles from our blog</T>
              <NextLink
                href="https://marginly.ghost.io/"
                target="_black"
                onClick={trackBlogClick}
              >
                <Button text>
                  <T action secondary>
                    Show All
                  </T>
                </Button>
              </NextLink>
            </BlogTitle>

            <Space height={48} heightMobile={32} />

            <BlogOverflow>
              <BlogGrid>
                {posts?.map((post) => (
                  <BlockItem key={post.url} post={post} />
                ))}
              </BlogGrid>
            </BlogOverflow>
          </GrayBlock>
        </section>

        <Space height={192} heightMobile={96} />

        <QaSection />

        <Space height={192} heightMobile={120} />
      </Container>
      <Footer />
    </>
  );
};

export default Main;
