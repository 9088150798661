"use client";

import styled, { keyframes } from "styled-components";

const assetsScroll = keyframes`
    0% {
        -webkit-transform: translate(00%, 0);
        transform: translate(0%, 0);
    }
    100% {
        -webkit-transform: translate(-100%, 0);
        transform: translate(-100%, 0)
    }
`;

// const blogCard = keyframes`
//     0% {
//       transform: translateY(50px);
//     }
//     100% {
//       transform: translateY(0px);
//     }
// `;

const swapFade = keyframes`
    0% {
      opacity: 0;
      display: none
    }
    15% {
      opacity: 1;
      display: block
      }
    28% {
      opacity: 1;
      display: block
    }
    53% {
      opacity: 0;
      display: none
    }
    100% {
      opacity: 0;
      display: none
    }
`;

export const TopBlock = styled.section``;

export const TogglesWrapper = styled.div``;

export const TogglesTop = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    gap: 12px;
    & > div:nth-child(2) {
      // transform: translateX(-100px);
      position: relative;
      align-self: flex-start;
    }
  }
`;

export const Toggle = styled.div`
  border-radius: var(--rounding-radius-xxxl, 48px);
  background: var(--fill-accent-primary, #9fe);
  display: flex;
  padding: var(--spacing-space-4, 4px) var(--spacing-space-4, 4px)
    var(--spacing-space-4, 4px) var(--spacing-space-24, 24px);
  align-items: center;
  gap: var(--spacing-space-12, 12px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;

  &.secondary {
    background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
    padding: var(--spacing-space-4, 4px) var(--spacing-space-24, 24px)
      var(--spacing-space-4, 4px) var(--spacing-space-4, 4px);
  }

  &.uniswap {
    height: 80px;
    background: transparent;
    border-radius: var(--rounding-radius-xxxl, 48px);
    padding: 0px var(--spacing-space-24, 24px);
    position: relative;
    transition: 300ms ease-out;
    // background: var(--fill-accent-secondary, rgba(50, 250, 220, 0.16));

    .animation {
      animation: ${swapFade} 6s infinite ease;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      animation-fill-mode: backwards;

      &:nth-child(2) {
        animation-delay: 1.98s;
      }
      &:nth-child(3) {
        animation-delay: 4.02s;
      }
    }
  }

  @media (max-width: 1023px) {
    width: fit-content;
    height: 80px;
    .headerL {
      font-size: 32px;
      line-height: 40px;
    }
    .headerM {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.24px;
    }
  }
`;

export const ToggleTrigger = styled.div`
  display: flex;
  padding: 18px var(--spacing-space-24, 24px) var(--spacing-space-22, 22px)
    var(--spacing-space-24, 24px);
  justify-content: center;
  align-items: center;
  border-radius: var(--rounding-radius-xxxl, 48px);
  background: var(--fill-elevated, #fff);
  box-shadow: 0px 8px 24px 0px rgba(51, 20, 0, 0.08),
    0px 4px 8px 0px rgba(51, 20, 0, 0.04);
  height: 80px;
  @media (max-width: 1023px) {
    height: 72px;
  }
`;

export const UniswapRow = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  & > *:nth-child(2) {
    width: 290px;
  }
`;

export const LendButton = styled.a`
  position: absolute;
  isolation: isolate;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  right: 0;
  top: 0;
  cursor: pointer;
  width: 210px;
  height: 210px;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    z-index: -1;
  }
  .text {
    color: var(--text-accent, #5cdfd4);
    font-size: 12px;
    font-weight: 500;
    line-height: 20.571px;
    transition: 200ms ease-out;
    letter-spacing: 0.12px;
    transform: translateY(10px);
    span {
      display: block;
      font-size: 42px;
      font-weight: 800;
      line-height: 49.172px;
    }
  }
  .action {
    margin-top: 6px;
    color: var(--text-primary, #000);
    opacity: 0;
    position: relative;
    transition: 200ms ease-out;
    border-radius: var(--rounding-radius-m, 16px);
    background: var(--fill-elevated, #fff);
    box-shadow: 0px 4px 8px 0px rgba(51, 20, 0, 0.08),
      0px 2px 1px 0px rgba(51, 20, 0, 0.04);
    padding: 5px 12px;
    transition: 200ms ease-out;
    opacity: 1;
    transform: translateY(0px);
    &:hover {
      background: #faf8f7;
    }
  }
  .text {
    transform: translateY(0px);
  }
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const MobileBnr = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  z-index: 99;

  .lend {
    position: absolute;
    right: 16px;
    bottom: 24px;
    p {
      color: var(--text-accent, #5cdfd4);
    }
  }

  @media (max-width: 1023px) {
    display: block;
  }
`;

export const LargeVariant = styled.div`
  border-radius: var(--rounding-radius-xxl, 32px)
    var(--rounding-radius-xxl, 32px) var(--rounding-radius-none, 0px)
    var(--rounding-radius-none, 0px);
  background: var(--fill-primary, #000);
  color: #fff;
  padding: 20px 24px 20px;
  position: relative;
  isolation: isolate;

  .close {
    position: absolute;
    right: 16px;
    top: -6px;
    transform: translateY(-100%);
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: 0;
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  p {
    color: var(--text-primary-on-dark, #fff);
  }
  .content {
    display: flex;
    gap: 16px;
  }
  .apy {
    color: var(--text-accent, #5cdfd4);
    text-align: center;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
`;

export const PicOrButton = styled.div`
  position: relative;
  .button {
    display: none;
  }

  .phone {
    display: flex;
    justify-content: center;
    transition: 300ms ease-out;
    &__img {
      height: 440px;
      object-fit: contain;
      object-position: center;
      transition: 300ms ease-out;
      z-index: -1;
      transform-origin: bottom center;
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    justify-content: center;
    .button {
      display: flex;
      border-radius: 40px !important;
      height: 80px !important;
      padding: 0px var(--spacing-space-48, 48px);
    }
    .phone {
      display: none;
    }
  }
`;

export const PhoneAssets = styled.div`
  position: absolute;
  bottom: 180px;
  left: 50%;
  transform: translate(-50%, 8px);
  display: flex;
  isolation: isolate;
  align-items: center;
  transition: 300ms ease-out;
  z-index: 4;
  & > * {
    position: relative;
    transition: 300ms ease-out;
    opacity: 0;
  }
  & > *:first-child,
  & > *:last-child {
    margin: 0 -31px;
  }
  & > *:last-child {
    z-index: -1;
  }
  & > *:first-child {
    transform: translateX(200%);
  }
  & > *:nth-child(2) {
    transform: translateX(100%);
  }
  & > *:nth-child(4) {
    transform: translateX(-100%);
  }
  & > *:last-child {
    transform: translateX(-200%);
  }
`;

export const PhoneContainer = styled.div`
  position: relative;

  &:hover {
    ${PhoneAssets} {
      transform: translate(-50%, 0);
      & > * {
        transform: translateX(0);
        opacity: 1;
      }
    }
    .phone__img {
      transform: scale(0.9);
      opacity: 0.24;
    }
  }
`;

export const TradingBtn = styled.a`
  text-decoration: none;
  border-radius: 40px;
  background: var(--fill-primary, #000);
  height: 80px;
  padding: 0px var(--spacing-space-48, 48px);
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 -31px;
  position: relative;
  z-index: 4;
  cursor: pointer;
  p {
    color: var(--text-invert-primary, #fff);
    white-space: nowrap;
  }
  &:hover {
    background: var(--fill-primary-hover);
  }
`;

export const Assets = styled.div`
  border-radius: var(--rounding-radius-xxl, 32px);
  background: var(--fill-elevated, #fff);
  box-shadow: 0px 8px 24px 0px rgba(51, 20, 0, 0.08),
    0px 4px 8px 0px rgba(51, 20, 0, 0.04);
  overflow: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  display: flex;
  align-items: center;
  &:hover > * {
    animation-play-state: paused;
  }
  @media (max-width: 1023px) {
    border-radius: 0;
    background: transparent;
    margin: 0 -24px;
    box-shadow: none;
  }
`;

export const AssetsFlex = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-space-8, 8px);
  animation: ${assetsScroll} 15s infinite linear;
`;

export const AssetsUnit = styled.a`
  width: 264px;
  display: flex;
  gap: 18px;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  padding: var(--spacing-space-24, 24px) 0;
  .percents {
    color: var(--text-negative, #e54796);
    &.up {
      color: var(--text-positive, #45be33);
    }
  }
`;

export const AssetsIcon = styled.div`
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--rounding-radius-m, 16px);
  background: var(--fill-negative-bg, rgba(240, 165, 204, 0.16));
  &.up {
    background: var(--fill-positive-bg, rgba(165, 217, 139, 0.16));
  }
  img {
    width: 28px;
    height: 28px;
    object-fit: contain;
    object-position: center;
  }
`;

export const Feature = styled.div`
  border-radius: var(--rounding-radius-xxl, 32px);
  background: var(--fill-accent-secondary, rgba(50, 250, 220, 0.16));
  padding: var(--spacing-space-24, 24px);
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin: 0 -4px;
  .icon {
    flex-shrink: 0;
    border-radius: var(--rounding-radius-xs, 8px);
    background: var(--fill-accent-primary, #9fe);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-space-12, 12px);
    width: 48px;
    height: 48px;
  }
  @media (max-width: 1023px) {
    margin: 0;
    & + & {
      margin-top: -4px;
    }
  }
`;

export const FeaturesWrapper = styled.section`
  margin: 0 4px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`;

export const Trading = styled.section`
  border-radius: 56px;
  background: var(--fill-elevated, #fff);
  box-shadow: 0px 8px 24px 0px rgba(51, 20, 0, 0.08),
    0px 4px 8px 0px rgba(51, 20, 0, 0.04);
  padding: var(--spacing-space-24, 24px) var(--spacing-space-24, 24px)
    var(--spacing-space-24, 24px) var(--spacing-space-64, 64px);
  gap: var(--spacing-space-48, 48px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  & > * {
    height: 504px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 1023px) {
    margin: 0 -24px;
    display: block;
    padding: var(--spacing-space-32, 32px) var(--spacing-space-24, 24px)
      var(--spacing-space-24, 24px) var(--spacing-space-24, 24px);

    & > * {
      height: auto;
      &:first-child {
        text-align: center;
        margin-bottom: 48px;
      }
    }
  }
`;

export const TradingSecondary = styled.div`
  border-radius: var(--rounding-radius-xxl, 32px);
  background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
  padding: var(--spacing-space-64, 64px) var(--spacing-space-24, 24px)
    var(--spacing-space-48, 48px) var(--spacing-space-24, 24px);
  align-items: center;
  @media (max-width: 1023px) {
    padding-top: 90px;
  }
`;

export const EngineBlock = styled.div`
  border: 1px dashed var(--border-secondary, rgba(66, 61, 60, 0.64));
  border-radius: var(--rounding-radius-m, 16px);
  position: relative;
  padding: var(--spacing-space-64, 64px) var(--spacing-space-24, 24px)
    var(--spacing-space-40, 40px) var(--spacing-space-24, 24px);
  flex-direction: column;
  align-items: center;
  display: flex;
  margin: 0 auto;
  width: fit-content;
  .marginly {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
  .lg.brand {
    background: var(--fill-elevated, #fff);
  }
`;

export const DexBtn = styled.button`
  border: 0;
  outline: 0;
  box-shadow: 0;
  border-radius: 40px;
  background: var(--fill-primary, #000);
  width: fit-content;
  padding: var(--spacing-space-4, 4px) var(--spacing-space-12, 12px);
  color: var(--text-invert-primary, #fff);
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.32px;
  width: 80px;
  height: 48px;
  cursor: auto;
  pointer-events: none;
  transition: 300ms ease-out;
  font-weight: 400;
  font-variation-settings: "wght" 700;
  &:hover {
    background: var(--fill-primary-hover);
  }
`;

export const WhyGrid = styled.div`
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-space-24, 24px);
  display: grid;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`;

export const WhyUnit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (max-width: 1023px) {
    .headerS {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.24px;
    }
    br {
      display: none;
    }
  }
`;

export const MobileYarnSection = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const MobileYarnSectionLink = styled.a`
  cursor: pointer;
  gap: 8px;
  padding: var(--spacing-space-16, 16px) var(--spacing-space-24, 24px);
  align-items: center;
  justify-content: space-between;
  display: flex;
  min-height: 80px;
  border-radius: var(--rounding-radius-xl, 24px);
  background: var(--fill-elevated, #fff);
  box-shadow: 0px 8px 24px 0px rgba(51, 20, 0, 0.08),
    0px 4px 8px 0px rgba(51, 20, 0, 0.04);
  .btn {
    background: var(--fill-positive, #45be33);
  }
`;

export const WhyIcon = styled.div`
  border-radius: var(--rounding-radius-xxxl, 48px);
  background: var(--fill-accent-primary, #9fe);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
`;

export const SmartContracts = styled.div`
  border-radius: var(--rounding-radius-xxxl, 48px);
  background: var(--fill-accent-secondary, rgba(50, 250, 220, 0.16));
  padding: var(--spacing-space-16, 16px) var(--spacing-space-32, 32px)
    var(--spacing-space-16, 16px) var(--spacing-space-16, 16px);
  gap: var(--spacing-space-16, 16px);
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width: fit-content;
  @media (max-width: 1023px) {
    flex-direction: column;
    width: 100%;
    padding: var(--spacing-space-32, 32px) var(--spacing-space-32, 32px)
      var(--spacing-space-32, 32px) var(--spacing-space-16, 16px);
  }
`;

export const SmartContractsIcon = styled.div`
  position: relative;
  .checkmark {
    position: absolute;
    top: -2px;
    right: -2px;
  }
`;

export const Solution = styled.section`
  border-radius: 56px;
  background: var(--background-primary, #fff);
  box-shadow: 0px 8px 24px 0px rgba(51, 20, 0, 0.08),
    0px 4px 8px 0px rgba(51, 20, 0, 0.04);
  padding: var(--spacing-space-48, 48px);
  & > * {
    text-align: center;
  }
  @media (max-width: 1023px) {
    margin: 0 -24px;
    padding: var(--spacing-space-32, 32px) var(--spacing-space-24, 24px)
      var(--spacing-space-24, 24px) var(--spacing-space-24, 24px);
  }
`;
export const SolutionRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 4px;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`;

export const SolutionBlock = styled.div`
  border-radius: var(--rounding-radius-xl, 24px);
  background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
  margin: 0 -4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacing-space-26, 26px) var(--spacing-space-24, 24px)
    var(--spacing-space-24, 24px);
  height: 284px;
  text-align: left;
  & > img {
    width: 32px;
    height: 32px;
  }
  .contact {
    padding: 0 4px;
  }
  .mobile {
    display: none;
  }
  @media (max-width: 1023px) {
    height: 220px;
    padding: var(--spacing-space-24, 24px);
    margin: 0;
    & + & {
      margin-top: -8px;
    }
    .mobile {
      display: block;
    }
  }
`;

export const MobileLogo = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px;
    img {
      height: 32px;
      object-fit: contain;
      object-position: center;
    }
  }
`;

export const BlogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .secondary {
    white-space: nowrap;
  }
  @media (max-width: 1023px) {
    align-items: center;
    a {
      margin-top: -34px;
    }
  }
`;

export const BlogOverflow = styled.div`
  @media (max-width: 1023px) {
    margin: 0 -24px -12px;
    padding: 0 24px 24px;
    overflow: scroll;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-color: transparent;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0 !important;
      height: 0 !important;
      display: none !important;
      opacity: 0;
      background-color: transparent;
    }

    &::-moz-scrollbar {
      width: 0 !important;
      height: 0 !important;
      display: none !important;
      opacity: 0;
      background-color: transparent;
    }

    &::-webkit-scrollbar:horizontal {
      width: 0 !important;
      height: 0 !important;
      display: none !important;
      opacity: 0;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 0 !important;
      height: 0 !important;
      opacity: 0;
      background: rgba(255, 255, 255, 0);
      background-color: transparent;
      display: none !important;
    }
    &::-webkit-scrollbar-track-piece {
      width: 0 !important;
      height: 0 !important;
      opacity: 0;
      background: rgba(255, 255, 255, 0);
      background-color: transparent;
      display: none !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
      opacity: 0;
    }

    &::-webkit-scrollbar-track {
      opacity: 0;
      background: transparent;
      background-color: transparent;
      width: 0 !important;
      height: 0 !important;
      display: none !important;
    }
  }
`;

export const Blog = styled.a`
  background: var(--fill-elevated, #fff);
  box-shadow: 0px 8px 24px 0px rgba(51, 20, 0, 0.08),
    0px 4px 8px 0px rgba(51, 20, 0, 0.04);
  display: block;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  animation-delay: 500ms;
  animation-play-state: paused;

  transition: 300ms ease-in;

  &:hover {
    background: var(--fill-elevated-hover);
  }

  @media (max-width: 1023px) {
    transform: translateY(0);
    animation: none;
    min-width: 342px;
  }
`;

export const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  ${Blog} {
    &:nth-child(2) {
      animation-delay: 400ms;
    }
    &:nth-child(3) {
      animation-delay: 500ms;
    }
  }
  @media (max-width: 1023px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    &::after {
      content: " ";
      width: 16px;
      display: block;
    }
  }
`;

export const EventsBanner = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--rounding-radius-xxl, 32px);
  background: var(--fill-elevated, #fff);
  box-shadow: 0px 8px 24px 0px rgba(51, 20, 0, 0.08),
    0px 4px 8px 0px rgba(51, 20, 0, 0.04);
  padding: 24px;
  gap: 16px;

  &.desktop {
    display: none;
  }

  @media (min-width: 1023px) {
    display: none;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 64px;
    &.desktop {
      // display: grid;
      display: block;
      max-width: 408px;
    }
  }
`;

export const EventsUnit = styled.a`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  & > div {
    align-items: center;
    display: flex;
    gap: 12px;
    max-width: calc(100% - 34px);
    div {
      max-width: calc(100% - 8px);
      overflow: hidden;
    }
  }
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .btn {
    background: var(--fill-positive, #45be33);
  }

  @media (min-width: 1023px) {
    .arrow {
      opacity: 0;
      transition: 200ms ease-in;
    }
    &:hover .arrow {
      opacity: 1;
    }
  }
`;

export const EventImg = styled.img`
  display: block;
  border-radius: var(--rounding-radius-xs, 8px);
  width: 48px;
  height: 48px;
  object-fit: cover;
  object-position: center;
  @media (min-width: 1023px) {
    width: 64px;
    height: 64px;
  }
`;
